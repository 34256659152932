<template>
  <div>
    <NavTitle class="mb16" title="商务提成核算" :showBack="false">
      <template #right>
        <div class="flex flex-end items-center">
          <el-button type="primary" @click="$refs.addPopupRef.open()">新建+</el-button>
          <div class="input-row mr16 ml16">
            <el-input class="input" placeholder="请输入商户ID" clearable v-model="searchWord" @clear="handleClear" @change="getAdviserList"></el-input>
            <el-button type="primary" icon="el-icon-search"  @click="getAdviserList"></el-button>
          </div>
          <el-date-picker
            class="mr16"
            v-model="screenDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getAdviserList">
          </el-date-picker>
          <el-button type="primary" size="medium">
            <span class="flex items-center">
              <span>下载</span>
              <img class="icon-download" src="@/assets/images/download-w.png">
            </span>
          </el-button>
        </div>
      </template>
    </NavTitle>
    <AddCommissionPopup ref="addPopupRef"></AddCommissionPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import AddCommissionPopup from './components/AddCommissionPopup.vue'
export default {
  components: {
    NavTitle,
    AddCommissionPopup
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 240px;
  display: flex;
  align-items: center;
}
.icon-download {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}
</style>